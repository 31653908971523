import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';
import { DeviceService } from './device.service'
import { Location } from '@angular/common';
import { Router, NavigationEnd } from "@angular/router";


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private deviceSservice: DeviceService, private location: Location, private router:Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    if (error.error instanceof ErrorEvent) {
                        console.error("Error Event");
                    } else {
                        switch (error.status) {
                            case 401:
                                if (error.error.jwt_expired == '1') {
                                    this.deviceSservice.resetToken().subscribe(
                                        data => {
                                            location.reload()
                                        },
                                        error => {
                                            console.log("ERROR" + JSON.stringify(error));
                                        });
                                }
                                else{
                                    localStorage.removeItem("currentUserToken");
                                    localStorage.removeItem("currentUserDetails");
                                    this.router.navigate(["/login"]);
                                }                               
                                break;
                            case 403:    
                                break;
                            case 503:
                                break;
                            default:
                                console.log('sonthing went wrong ');
                                break
                        }
                    }
                } else {
                    console.error("some thing else happened");
                }
                return throwError(error);

            })
        );
    }
}
