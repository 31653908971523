import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "@environments/environment";
import { User } from "../modules";
import { HttpHeaders, HttpParams } from "@angular/common/http";

const httpHeaders = new HttpHeaders()
    .set("X-Requested-With", "XMLHttpRequest")
    // .set("subdomain", "admin");

const afterLoginHeader = new HttpHeaders();
@Injectable({ providedIn: "root" })
export class MasterComponentService {
    private currentUserSubject: BehaviorSubject<User>;
    private currentUserData: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem("currentUserToken"))
        );
        this.currentUserData = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem("currentUserDetails"))
        );

        this.currentUser = this.currentUserSubject.asObservable();

        afterLoginHeader
            .set("Authorization", `Bearer ${localStorage.getItem("currentUserToken")}`)
            .set("X-Requested-With", "XMLHttpRequest")
           // .set("subdomain", "admin");
    }
    public get currentUserValue(): User {

        return this.currentUserSubject.value;
    }

    // GET MASTER COMPONENT LIST
    getComponentList(): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("sort_param", "created_at");
        data.append("sort_by", "desc")
        return this.http
            .post<any>(`${environment.apiUrl}/component/list`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    // GET MASTER COMPONENT DETAIL BY ID
    getComponentById(id): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");

        return this.http
            .get<any>(`${environment.apiUrl}/component/${id}`, {
                params: { locale: "en" },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    createComponent(values): Observable<any> {

        var data: any = new FormData();
        data.append("locale", "en");
        data.append("name", values.name);
        data.append("description", values.description);
        data.append("type", values.type);
        data.append("attribute[]", values.attribute);
        data.append("icon", values.icon);

        return this.http
            .post<any>(`${environment.apiUrl}/component`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    deleteComponent(id): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");

        return this.http
            .delete<any>(`${environment.apiUrl}/component/${id}`, {
                params: {
                    locale: "en"
                },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    // component fields

    getComponentFieldList(id): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");
        data.append('component_id', id);
        data.append("sort_param", "created_at");
        data.append("sort_by", "desc")
        return this.http
            .post<any>(`${environment.apiUrl}/component/field/list`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    createComponentFields(id,value): Observable<any> {

        var data: any = new FormData();
        data.append("locale", "en");
        data.append("component_id", id);
        for (var i = 0; i < value.itemRows.length; i++) {
            data.append('name[]', value.itemRows[i].name);
            data.append('description[]', value.itemRows[i].description);
            data.append('datatype[]','string');
            data.append('field[]', value.itemRows[i].field);
        }  
    
        return this.http
            .post<any>(`${environment.apiUrl}/component/field`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    deleteField(id): Observable<any> {

        console.log('DELETED ID=== '+id);
        var data: any = new FormData();
        data.append("locale", "en");

        return this.http
            .delete<any>(`${environment.apiUrl}/component/field/${id}`, {
                params: {
                    locale: "en"
                },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }



    updateComponentInfo(id,name,description,attribute,type,icon){

    var data: any = new FormData();
        data.append("locale", "en");
        data.append("name", name);
        data.append("type",type);
        data.append("icon",icon);
        data.append("description",description);
        data.append("attribute",attribute);    
        return this.http
            .post<any>(`${environment.apiUrl}/component/${id}`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }


}
