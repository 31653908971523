import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map, concatMap } from "rxjs/operators";

import { environment } from "@environments/environment";
import { User } from "../modules";
import { HttpHeaders, HttpParams } from "@angular/common/http";
// import { concat } from 'bytebuffer';

const httpHeaders = new HttpHeaders()
    .set("X-Requested-With", "XMLHttpRequest")
    // .set("subdomain", "admin");

const afterLoginHeader = new HttpHeaders();
@Injectable({ providedIn: "root" })
export class DeviceService {
    private currentUserSubject: BehaviorSubject<User>;
    private currentUserData: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem("currentUserToken"))
        );
        this.currentUserData = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem("currentUserDetails"))
        );

        this.currentUser = this.currentUserSubject.asObservable();

        afterLoginHeader
            .set("Authorization", `Bearer ${this.currentUserSubject}`)
            .set("X-Requested-With", "XMLHttpRequest")
        //.set("subdomain", "admin");
    }
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    // GET MASTER COMPONENT LIST
    getDeviceTypeList(): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("sort_param", "created_at");
        data.append("sort_by", "desc");

        return this.http
            .post<any>(`${environment.apiUrl}/device/type/list`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    //this.currentUserSubject.next(user);
                    return data;
                })
            );
    }



    createDeviceType(values): Observable<any> {

        var data: any = new FormData();
        data.append("locale", "en");
        data.append("name", values.name);
        data.append("description", values.description);
        data.append("type", values.type);
        data.append("attribute", values.attribute);
        data.append("icon", values.icon);

        return this.http
            .post<any>(`${environment.apiUrl}/device/type`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    //this.currentUserSubject.next(user);
                    return data;
                })
            );
    }



    getDeviceTypeById(id): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");

        return this.http
            .get<any>(`${environment.apiUrl}/device/type/${id}`, {
                params: { locale: "en" },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    };

    updateDeviceTypeById(formValue, id): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("name", formValue.name);
        data.append("icon", formValue.icon);
        data.append("description", formValue.description);
        data.append("standard_unit_type", formValue.standardUnitType);
        data.append("standard_unit",  formValue.standardUnit);

        return this.http
            .post<any>(`${environment.apiUrl}/device/type/${id}`, data)
            .pipe(
                map(data => {
                    return data;
                }))
    };


    deleteDeviceType(id): Observable<any> {
        // let data = {
        //     locale: "en"
        // };

        var data: any = new FormData();
        data.append("locale", "en");

        return this.http
            .delete<any>(`${environment.apiUrl}/device/type/${id}`, {
                params: {
                    locale: "en"
                },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }


    // DEVICE DETAILS AS PER DEVICE TYPE SELECTED


    getDeviceList(id): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("device_type_id", id);
        data.append("sort_param", "created_at");
        data.append("sort_by", "desc")
        return this.http
            .post<any>(`${environment.apiUrl}/device/list`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    //this.currentUserSubject.next(user);
                    return data;
                })
            );
    }


    createDevice(device_type_id, values): Observable<any> {

        var data: any = new FormData();
        data.append("locale", "en");
        data.append('device_type_id', device_type_id);
        data.append("name", values.name);
        data.append("description", values.description);
        data.append("total_component", values.count);

        return this.http
            .post<any>(`${environment.apiUrl}/device`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    //this.currentUserSubject.next(user);
                    return data;
                })
            );
    }

    getDeviceById(id): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");

        return this.http
            .get<any>(`${environment.apiUrl}/device/${id}`, {
                params: { locale: "en" },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    };



    deleteDevice(id): Observable<any> {
        // let data = {
        //     locale: "en"
        // };

        var data: any = new FormData();
        data.append("locale", "en");

        return this.http
            .delete<any>(`${environment.apiUrl}/device/${id}`, {
                params: {
                    locale: "en"
                },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }



    // DEVICE COMPONENT 


    getDeviceComponentlist(device_uid): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("device_uid", device_uid);
        // data.append("sort_param", "created_at");
        data.append("sort_by", "desc")
        return this.http
            .post<any>(`${environment.apiUrl}/device/component/list`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    //this.currentUserSubject.next(user);
                    return data;
                })
            );
    }



    deleteDeviceComponent(id) {
        var data: any = new FormData();
        data.append("locale", "en");

        return this.http
            .delete<any>(`${environment.apiUrl}/device/component/${id}`, {
                params: {
                    locale: "en"
                },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    createDeviceComponent(deviceUid, value): Observable<any> {

        var data: any = new FormData();
        data.append("locale", "en");
        data.append("device_uid", deviceUid);
        for (var i = 0; i < value.itemRows.length; i++) {
            data.append('device_component_name[]', value.itemRows[i].name);
            data.append('device_component_desc[]', value.itemRows[i].description);
            data.append('component_id[]', value.itemRows[i].masterComponent);
            data.append('gpio[]', value.itemRows[i].gpio);
        }

        return this.http
            .post<any>(`${environment.apiUrl}/device/component`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    //this.currentUserSubject.next(user);
                    return data;
                })
            );
    }



    getDeviceComponentById(id): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");

        return this.http
            .get<any>(`${environment.apiUrl}/device/component/${id}`, {
                params: { locale: "en" },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }



    // TOKEN REFRESH AFTER EXPIRE

    resetToken(): Observable<any> {
        let data = {
            locale: "en"
        };
        return this.http
            .get<any>(`${environment.apiUrl}/user/token/refresh`, {
                params: { locale: "en" },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    localStorage.setItem(
                        "currentUserToken",
                        JSON.stringify(data.data.token)
                    );
                    return data;
                })
            );
    }


    // DATA log

    componentDatalogging(deviceUid, gpio, perPage): Observable<any> {      
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("device_uid", deviceUid);
        data.append("gpio", gpio);
        data.append("quickDateRange", 'today');
        if (perPage != '0') {
            data.append("per_page", perPage);
        }

        return this.http
            .post<any>(`${environment.apiUrl}/device/data/list`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    };

    componentDatalogging_forFilter(deviceUid, gpio, perPage,filterValue?:any, dateFilter?:any): Observable<any> {      
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("device_uid", deviceUid);
        data.append("gpio", gpio);
        if(filterValue==undefined){
            data.append("quickDateRange", 'lastWeek')
        } else {
            data.append("quickDateRange",filterValue)
        }
        if (perPage != '0') {
            data.append("per_page", perPage);
        }

        return this.http
            .post<any>(`${environment.apiUrl}/device/data/list`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    // Client Device

    activateDevice(values): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("device_uid", values.sno);
        data.append("log_frequency_in_ms",values.frequency)

        return this.http
            .get<any>(`${environment.apiUrl}/device/activate`, {
                params: {
                    locale: "en",
                    device_uid: values.sno,
                    log_frequency_in_ms : values.frequency
                },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }


    updateDeviceComponent(id, val): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("device_component_tag", val[0].value);
        data.append("device_component_name", val[1].value);
        data.append("device_component_desc", val[2].value);
        data.append("gpio", val[3].value);

        for (var i = 4; i < val.length; i++) {

            data.append(`device_component_field[${val[i].key}]`, val[i].value);
        }


        return this.http
            .post<any>(`${environment.apiUrl}/device/component/${id}`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    //this.currentUserSubject.next(user);
                    return data;
                })
            );
    }

    generateSecretKey(id) {

        var data: any = new FormData();
        data.append("locale", "en");

        return this.http
            .post<any>(`${environment.apiUrl}/device/secret-key/${id}`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }


    updateDeviceInfo(value) {
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("device_type_id", value.deviceTypeId);
        data.append("name", value.device_name);
        data.append("description", value.description);
        data.append("log_frequency_in_ms",value.log_frequency_in_ms)
        // data.append("total_component", value.total_component);
        return this.http
            .post<any>(`${environment.apiUrl}/device/${value.id}`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }


    getReportByDateRange(device_uid, gpio, to_date, from_date) {
        console.log(to_date + ' == ' + from_date)

        var data: any = new FormData();
        data.append("locale", "en");
        data.append("device_uid", device_uid);
        data.append("gpio", gpio);
        data.append("from_date", from_date);
        data.append("to_date", to_date);
        return this.http
            .post<any>(`${environment.apiUrl}/device/data/report`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    updateComponentFeature(id) {
        var data: any = new FormData();
        data.append("locale", "en");

        return this.http
            .get<any>(`${environment.apiUrl}/device/component/featured/${id}`, {
                params: { locale: "en" },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    getFeaturedComponent() {
        return this.http
            .get<any>(`${environment.apiUrl}/device/component/featured`, {
                params: { locale: "en" },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }
    getfeatureLog(device_uid, gpio) {

    }

    getUnitTypeList(): Observable<any> {
        return this.http
            .get<any>(`${environment.apiUrl}/master-data`, {
                params: { locale: "en" },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }
}
