import { Component, OnInit ,Output, EventEmitter } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
    FormBuilder,
    FormGroup,
    FormArray,
    Validators,
    FormControl
} from "@angular/forms"; import { MasterComponentService, UserService } from "@app/shared/services";
@Component({
    selector: 'add-client-modal',
    templateUrl: './add-client-modal.component.html',
    styleUrls: ['./add-client-modal.component.scss']
})
export class AddClientModalComponent {
    closeResult: string;
    demoForm: FormGroup;
    @Output() valueChange = new EventEmitter();
    refreshList:boolean=false;
    form = new FormGroup({
        fname: new FormControl(""),
        lname: new FormControl(""),
        email: new FormControl(""),
        dial_code: new FormControl(""),
        mobile: new FormControl(""),
        subdomain: new FormControl(""),
    });


    clientCreateForm = new FormGroup({
        fname: new FormControl("", Validators.required),
        lname: new FormControl("", Validators.required),
        email: new FormControl("", [Validators.required, Validators.email]),
        dial_code: new FormControl("", Validators.required),
        mobile: new FormControl('', [
            Validators.required,
            Validators.pattern("^[0-9]*$"),
            Validators.minLength(10),
        ]),
        subdomain: new FormControl("", Validators.required),
    });
    submitted = false;
    error = "";
    loading = false;

    constructor(
        private modalService: NgbModal,
        private _formBuilder: FormBuilder,
        private componentService: MasterComponentService,
        private userService: UserService
    ) {
        this.demoForm = this._formBuilder.group({
            demoArray: this._formBuilder.array([])
        });

    }

    open(content) {
        this.modalService.open(content, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    get demoArray() {
        return this.demoForm.get('demoArray') as FormArray;
    }
    get f() {
        return this.clientCreateForm.controls;
    }

    onSubmit() {     
        this.submitted = true;
        if (this.clientCreateForm.invalid) {
            return;
        }
        this.loading = true;

        this.userService
            .createClient(this.clientCreateForm.value)
            .subscribe(
                data => {
                    this.loading = false;
                    this.onReset();
                },
                error => {
                    this.loading = false;
                    console.log("ERROR " + JSON.stringify(error));
                }

                // error => {
                //     let errorMessage = error.error.errors;
                //     let errData= '';
                //     for (var i = 0; i < errorMessage.component_id.length; i++) { 
                //         this.error= errorMessage.component_id[i];
                //     }
                // }
            );
    }

    onReset() {
        this.submitted = false;
        this.clientCreateForm.reset();
        this.valueChange.emit(this.refreshList);
        this.modalService.dismissAll();
    }
}

