import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserLayoutComponent } from './user-layout.component';
import {UserAuthGuard} from '../shared/guard';
const routes: Routes = [
    {
        path: '',
        component: UserLayoutComponent,
        // canActivateChild : [UserAuthGuard],
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'prefix' },
            { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'profile/:id', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
            { path: 'devices/:id', loadChildren: () => import('./devices/devices.module').then(m => m.DevicesModule) },
            {path: 'devices-type', loadChildren:() => import ('./devices-type/devices-type.module').then(m => m.DevicesTypeModule)},
            
            {path: 'report', loadChildren:() => import ('./report/report.module').then(m => m.ReportModule)},
            {path: 'report-details', loadChildren:() => import ('./report-details/report-details.module').then(m => m.ReportDetailsModule)},

            {
                path: 'device-details/:deviceTypeId/:id',
                loadChildren: () => import('./device-details/device-details.module').then(m => m.DeviceDetailsModule)
            },
            {
                path: 'device-component-details/:id',
                loadChildren: () => import('./device-component-details/device-component-details.module').then(m => m.DeviceComponentDetailsModule)
            },
            { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
            { path: 'components', loadChildren: () => import('./bs-component/bs-component.module').then(m => m.BsComponentModule) },
            { path: 'inventory', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule) },
            
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserLayoutRoutingModule {}
