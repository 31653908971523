import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class TenantService {
  host: any;
  constructor() {}

  getTenantForHostname(hostname: string) {
    let name = hostname.split(".")[0];
    return  name;
  }

  getTenantForString(s: string) {
    for (const e in Tenant) {
      if (e.toLowerCase() === s.toLowerCase()) {
        return Tenant[e] as Tenant;
      }
    }
    return null;
  }

  getTenantForHost(host: string) {
    return this.getTenantForString(host);
  }

  getTenant() {
    if (localStorage.getItem('clientToken')) {
      localStorage.removeItem('tenant');
      let subTenant = localStorage.getItem('subTenant')
      this.host = `${subTenant}.iloggerz.com`;
    }
    else {
      localStorage.removeItem('clientToken');
      localStorage.removeItem('subClientId');
      localStorage.removeItem('subTenant');

      this.host= location.hostname;
      //this.host = 'konverters.iloggerz.com';
      //this.host = 'clienta.iloggerz.com';
      // this.host = 'admin.iloggerz.com';
      // this.host = 'hvacdev1.iloggerz.com';
    }
    return this.getTenantForHostname(this.host);
  }

  addTenantToHeaders(headers: HttpHeaders): HttpHeaders {
    return headers.append("X-Tenant-ID", this.getTenant());
  }
}

export enum Tenant {
  CLIENT1 = "client1",
  CLIENT2 = "client2"
}