import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { AuthenticationService } from './authentication.service';

// const socket = io('https://socket.iloggerz.com', {
//   query: {
//   }
// });



@Injectable()
export class SocketService {
  socketDetail: any;
  socketconnected: any;
  sessionId: any;
  // private url = 'http://116.202.106.140:3038/';
  private url = 'https://socket.iloggerz.com';
  private socket;


  constructor(
    private authService: AuthenticationService
  ) {
    const currentUser = this.authService.currentUserValue;

    this.socket = io(this.url, {
      query: {
        token: currentUser
      }
    });  
  }

  updateConfig(currentUser) {
    this.socket['query']['token'] = currentUser;
  }

  deviceConnection(currentUser, deviceUID) {
    this.socket = io(this.url, {
      query: {
        token: currentUser,
        isDevice: true,
        deviceUID: deviceUID
      }
    })
  }

  resetDevice(deviceUID) {

    // const currentUserToken = this.authService.currentUserValue;
    // const socketId = this.socket.id
    // this.deviceConnection(currentUserToken, deviceUID);
    // if (socketId) {
      let dataObj = {
        "sessionID": this.socket.id,
        "deviceUID": deviceUID
      };
      this.socket.emit('restartMcu', dataObj, (data) => {
        console.log('restartMcu', data)
      });

      this.socket.on('errorMessage', (data) => {
        console.log(data)
      });
      this.socket.on('successMessage', (data) => {
        console.log(data)
      });
    // }
  }

}