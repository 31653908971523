import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared/guard';

let tenant = localStorage.getItem('tenant');
let data: Array<any> = [];

// if(tenant=='admin'){
//     data= [
//         { path: 'layout', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard] },

//         { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
//         { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
//         { path: 'forget-password', loadChildren: () => import('./forget-password/forget-password.module').then(m => m.ForgetPasswordModule) },
//         {
//             path: 'forget-password-otp/:id',
//             loadChildren: () => import('./forget-password-otp/forget-password-otp.module').then(m => m.ForgetPasswordOtpModule)
//         },
//         { path: 'reset-password/:otp/:id', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
//         { path: 'set-password', loadChildren: () => import('./set-password/set-password.module').then(m => m.SetPasswordModule) },

//         { path: 'error', loadChildren: () => import('./server-error/server-error.module').then(m => m.ServerErrorModule) },
//         { path: 'access-denied', loadChildren: () => import('./access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
//         { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
//         { path: '**', redirectTo: 'not-found' }
//     ];   
// }
// else{
//     data= [
//         { path: '', loadChildren: () => import('./user-layout/user-layout.module').then(m => m.UserLayoutModule), canActivate: [AuthGuard]},

//         { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
//         { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
//         { path: 'forget-password', loadChildren: () => import('./forget-password/forget-password.module').then(m => m.ForgetPasswordModule) },
//         {
//              path: 'forget-password-otp/:id',
//              loadChildren: () => import('./forget-password-otp/forget-password-otp.module').then(m => m.ForgetPasswordOtpModule)
//         },
//         { path: 'reset-password/:otp/:id', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
//         { path: 'set-password', loadChildren: () => import('./set-password/set-password.module').then(m => m.SetPasswordModule) },

//         { path: 'error', loadChildren: () => import('./server-error/server-error.module').then(m => m.ServerErrorModule) },
//         { path: 'access-denied', loadChildren: () => import('./access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
//         { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
//         { path: '**', redirectTo: 'not-found' }
//     ];    
// }

// const routes: Routes = data

let routes: Routes = [
    // {
    //     path: '',
    //     canActivate: [AuthGuard],
        //children: [
        //   {
        //     path: '',
        //     loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
        //     canActivate: [AdminAuthGuard]
        //   },{
        //   path: '',
        //   loadChildren: () => import('./user-layout/user-layout.module').then(m => m.UserLayoutModule),
        //   canActivate: [UserAuthGuard],
        // },
       
       // ],
    //  },

    // { path: '', loadChildren: () => import('./user-layout/user-layout.module').then(m => m.UserLayoutModule), canActivate: [UserAuthGuard]},
    // { path: ' ', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard]},
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
    { path: 'forget-password', loadChildren: () => import('./forget-password/forget-password.module').then(m => m.ForgetPasswordModule) },
    {
        path: 'forget-password-otp/:id',
        loadChildren: () => import('./forget-password-otp/forget-password-otp.module').then(m => m.ForgetPasswordOtpModule)
    },
    { path: 'reset-password/:otp/:id', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
    { path: 'set-password', loadChildren: () => import('./set-password/set-password.module').then(m => m.SetPasswordModule) },

    { path: 'error', loadChildren: () => import('./server-error/server-error.module').then(m => m.ServerErrorModule) },
    { path: 'access-denied', loadChildren: () => import('./access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
    { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
    { path: '**', redirectTo: 'not-found' }
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { 
  constructor(private router: Router) {   
    if(localStorage.getItem('tenant')=='admin'){


      if(localStorage.getItem('clientToken')){
        routes.unshift({
          path:'', loadChildren:() => import('./user-layout/user-layout.module').then(m => m.UserLayoutModule), canActivate: [AuthGuard]
        })
      }
      else{
        routes.unshift({
          path:'', loadChildren:() => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard]
        })
      }    
  }
  else{

    routes.unshift({
      path:'', loadChildren:() => import('./user-layout/user-layout.module').then(m => m.UserLayoutModule), canActivate: [AuthGuard]
    }) 
  }
    router.resetConfig(routes);
  }



}









// import { NgModule } from '@angular/core';
// import { Routes, RouterModule, Router } from '@angular/router';
// import { AppComponent } from './app.component';
// import { AuthGuard } from './shared/guard';
// import {UserLayoutComponent} from './user-layout/user-layout.component';
// import {LayoutComponent} from './layout/layout.component';
// let tenant = localStorage.getItem('tenant');
// let data: Array<any> = [];

// let routes: Routes = [
//        { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
//     { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
//     { path: 'forget-password', loadChildren: () => import('./forget-password/forget-password.module').then(m => m.ForgetPasswordModule) },
//     {
//         path: 'forget-password-otp/:id',
//         loadChildren: () => import('./forget-password-otp/forget-password-otp.module').then(m => m.ForgetPasswordOtpModule)
//     },
//     { path: 'reset-password/:otp/:id', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
//     { path: 'set-password', loadChildren: () => import('./set-password/set-password.module').then(m => m.SetPasswordModule) },

//     { path: 'error', loadChildren: () => import('./server-error/server-error.module').then(m => m.ServerErrorModule) },
//     { path: 'access-denied', loadChildren: () => import('./access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
//     { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
//     { path: '**', redirectTo: 'not-found' }
// ];

// @NgModule({
//     imports: [RouterModule.forRoot(routes)],
//     exports: [RouterModule]
// })
// export class AppRoutingModule { 
//   constructor(private router: Router) {   
//     if(localStorage.getItem('tenant')=='admin'){
// routes= this.router.config;
//       if(localStorage.getItem('clientToken')){
//         this.router.config.unshift({
//           // path:'', loadChildren:() => import('./user-layout/user-layout.module').then(m => m.UserLayoutModule), canActivate: [AuthGuard]
//           path:'',
//           component: UserLayoutComponent,
//           canActivate: [AuthGuard]
//         })
//       }
//       else{
//         this.router.config.unshift({
//           // path:'', loadChildren:() => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard]
//           path:'',
//           component: LayoutComponent,
//           canActivate: [AuthGuard]

//         })
//       }    
//   }
//   else{
//     this.router.config.unshift({
//       path:'',
//       component: UserLayoutComponent,
//    // loadChildren:() => import('./user-layout/user-layout.module').then(m => m.UserLayoutModule),
//       canActivate: [AuthGuard]
//     }) 
//   }
//     router.resetConfig(routes);
//   }



// }
