import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "@environments/environment";
import { User } from "../modules";
import { HttpHeaders, HttpParams } from "@angular/common/http";

const httpHeaders = new HttpHeaders()
    .set("X-Requested-With", "XMLHttpRequest")
    // .set("subdomain", "admin");

const afterLoginHeader = new HttpHeaders();
@Injectable({ providedIn: "root" })
export class UserService {
    private currentUserSubject: BehaviorSubject<User>;
    private currentUserData: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem("currentUserToken"))
        );
        this.currentUserData = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem("currentUserDetails"))
        );

        this.currentUser = this.currentUserSubject.asObservable();

        afterLoginHeader
            .set("Authorization", `Bearer ${this.currentUserSubject}`)
            .set("X-Requested-With", "XMLHttpRequest")
            //.set("subdomain", "admin");
    }
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    // GET CLIENT LIST
    getClientList(): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("sort_param", "created_at");
        data.append("sort_by", "desc")
    
        return this.http
            .post<any>(`${environment.apiUrl}/client/list`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

  

    createClient(values): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("fname", values.fname);
        data.append("lname", values.lname);
        data.append("email", values.email);
        data.append("dial_code", values.dial_code);
        data.append("mobile", values.mobile);
        data.append("subdomain", values.subdomain);      

        return this.http
            .post<any>(`${environment.apiUrl}/signup`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

   updateClientStatus(id): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");

        return this.http
            .get<any>(`${environment.apiUrl}/client/status/${id}`, {
                params: {
                    locale: "en"
                },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    //UPDATE EMAIL SUBSCRIPTION

    updateEmailSubscription(id):Observable<any> {
        var data =new FormData();

        data.append("locale","en");

        return this.http.get<any>(`${environment.apiUrl}/client/devicedata-subscription/${id}`,{
            params:{
                locale:"en"
            },
            headers:httpHeaders
        })
        .pipe(
            map(data => {
                return data;
            })
        )
    }


    // USER DETAILS
  

    getUserList(): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("sort_param", "created_at");
        data.append("sort_by", "desc")
        return this.http
            .post<any>(`${environment.apiUrl}/user/list`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }


    getUserById(id): Observable<any> {  
        var data: any = new FormData();
        data.append("locale", "en");

        return this.http
            .get<any>(`${environment.apiUrl}/user/${id}`, {
                params: { locale: "en" },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    deleteUser(id): Observable<any> {
   
        var data: any = new FormData();
        data.append("locale", "en");

        return this.http
            .delete<any>(`${environment.apiUrl}/user/${id}`, {
                params: {
                    locale: "en"
                },
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }


    updateProfile(fname,lname,email, mobile, dob, address){
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("fname", fname);
        data.append("lname", lname);
        data.append("email",email);
        data.append("dial_code",'91');
        data.append("mobile",mobile);
        data.append("gender","Male");
        data.append("dob",dob);
        data.append("address",address)
        return this.http
            .post<any>(`${environment.apiUrl}/user/profile`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }  

    updateProfileImage(image){      
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("profile_image", image);
        return this.http
            .post<any>(`${environment.apiUrl}/user/profile-image`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    loginAs(uid,fcm_id,device_id,device_type){   
        let fcmToken= localStorage.getItem('fcmToken');
   
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("user_uid", uid);
        data.append("fcm_id", fcmToken);
        data.append("device_id", fcmToken);
        data.append("device_type", 'web')
        return this.http
            .post<any>(`${environment.apiUrl}/loginAs`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }
notificationList(){
    let data:any= new FormData();
    data.append("locale","en");

    return this.http
    .post<any>(`${environment.apiUrl}/notification/list`, data, {
        headers: httpHeaders
    })
    .pipe(
        map(data => {
            return data;
        })
    );
}
markNotificationRead(id){

    var data: any = new FormData();
    data.append("locale", "en");

    return this.http
        .get<any>(`${environment.apiUrl}/notification/${id}`, {
            params: {
                locale: "en"
            },
            headers: httpHeaders
        })
        .pipe(
            map(data => {
                return data;
            })
        );

}

}
