import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
    @Input() heading: string;
    @Input() icon: string;
    @Input() device_type_id:any;
    @Input() id:any;
    @Output() valueChange = new EventEmitter();
    @Output() deviceValueChange = new EventEmitter();
    @Output() clientValueChange = new EventEmitter();
    @Output() componentValueChange = new EventEmitter();
    // @Output() deviceTypeValueChange = new EventEmitter();

    constructor() {}

    ngOnInit() {  };

    getUserList(){
        this.valueChange.emit()
    };

    getDeviceList(){
        this.deviceValueChange.emit()
    };

    getClientList(){
        this.clientValueChange.emit();
    };

    getComponentList(){
        this.componentValueChange.emit()
    };

    // getListData(){
    //     this.deviceTypeValueChange.emit();
    // }
}
