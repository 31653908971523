import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthenticationService } from '../services';
import { UserAuthGuard} from './userauth.guard';
import { AdminAuthGuard} from './adminauth.guard';

@Injectable()
export class AuthGuard implements CanActivate { 
    // CanActivateChild

    // constructor(private router: Router,
    //     private userGuard: UserAuthGuard,
    //     private adminGuard: AdminAuthGuard) { }
    
    //   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        // console.log('INITIAL ==== '+route)

        // let canGo = [{
        //   guard: this.adminGuard,
        //   url: '',
        // }, {
        //   guard: this.userGuard,
        //   url: '',
        // }].some((config) => {
        //   console.log('config', config);
        //   console.log('== '+config.url);

        //   if (config.guard.canActivate(route, state)) {
        //     console.log(route)
        //     if (state.url !== `/${config.url}`) {
        //       console.log('canActivate 11==  '+config.url+'=='+ state.url)
        //       return true;
        //     }
        //   } 
        // });
        // console.log('cango', canGo)
        // return canGo; 
    //   }
    // }

    constructor(private router: Router, private authenticationService: AuthenticationService) {
    }

    canActivate(): boolean {
        const currentUser = this.authenticationService.currentUserValue;
        const tenant = localStorage.getItem('tenant');
        if (currentUser) {
            console.log('auth gaurt if condi',tenant);
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {

        if (localStorage.getItem("tenant") == 'admin') {
            return true;
        }
        return false;
    }
}
