import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { TenantService } from "./tenant.service";
import { SocketService } from './socket.service';
import { stringify } from 'querystring';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private tenantService: TenantService,
        private socketService: SocketService
    ) { }
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const currentUser = this.authenticationService.currentUserValue;
        const subToken= localStorage.getItem("clientToken");
        const subTenant= localStorage.getItem("subTenant");

        const tenant = this.tenantService.getTenant();
        localStorage.setItem("tenant", tenant);
        if (currentUser) {
            if(subToken){
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${subToken}`,
                        subdomain: subTenant
                    }
                });
                this.socketService.updateConfig(subToken);
            }
            else{
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUser}`,
                        subdomain: tenant
                    }
                });
                this.socketService.updateConfig(currentUser);
            }
          
        }
        else {
            request = request.clone({
                setHeaders: {
                    subdomain: tenant
                }
            });
        }
        return next.handle(request);
    }
}
