import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PageHeaderComponent } from './page-header.component';
import { AddUserModalComponent } from '@app/user-layout/bs-component/components/add-user-modal/add-user-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AddDeviceModalComponent } from '@app/user-layout/bs-component/components/add-device-modal/add-device-modal.component';
import { AddClientModalComponent } from '@app/layout/bs-component/components/add-client-modal/add-client-modal.component';
import { MasterComponentModalComponent } from '@app/layout/bs-component/components/master-component-modal/master-component-modal.component';
import { DeviceTypeModalComponent } from '@app/user-layout/bs-component/components/device-type-modal/device-type-modal.component';

@NgModule({
    imports: [CommonModule,
        RouterModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        PageHeaderComponent,
        AddUserModalComponent,
        AddDeviceModalComponent,
        AddClientModalComponent,
        MasterComponentModalComponent],
    exports: [PageHeaderComponent]
})
export class PageHeaderModule { }
