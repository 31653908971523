import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "@environments/environment";
import { User } from "../modules";
import { HttpHeaders, HttpParams } from "@angular/common/http";

const httpHeaders = new HttpHeaders()
    .set("X-Requested-With", "XMLHttpRequest")
    // .set("subdomain", "admin");

const afterLoginHeader = new HttpHeaders();
@Injectable({ providedIn: "root" })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    private currentUserData: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem("currentUserToken"))
        );
        this.currentUserData = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem("currentUserDetails"))
        );

        this.currentUser = this.currentUserSubject.asObservable();
    }
    public get currentUserValue(): User {
        //return this.currentUserSubject.value;
        return JSON.parse(localStorage.getItem("currentUserToken"));

    }


    login(
        email: string,
        password: string,
        fcm_id: string,
        device_id: string,
        device_type: string
    ): Observable<any> {
        let fcmToken= localStorage.getItem('fcmToken');
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("email", email);
        data.append("password", password);
        data.append("fcm_id", fcmToken || "test");
        data.append("device_id", fcmToken || "testdevice");
        data.append("device_type", 'web');

        return this.http
            .post<any>(`${environment.apiUrl}/login`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(user => {
                    localStorage.setItem(
                        "currentUserToken",
                        JSON.stringify(user.data.token)
                    );
                    localStorage.setItem(
                        "currentUserDetails",
                        JSON.stringify(user.data.data)
                    );

                    localStorage.setItem(
                        "currentUserId",user.data.data.id
                    );
                    localStorage.setItem(
                        "currentUserName", user.data.data.fname+" "+user.data.data.lname
                    );
                    // localStorage.setItem(
                    //     "currentUserImage", user.data.data.profile_image
                    // );
                    this.currentUserSubject.next(user);
                    return user;
                })
            );
    }

    logout(): Observable<any> {
        var data: any = new FormData();
        data.append("locale", "en");
        data.append("device_id", "123456789");

        return this.http
            .post<any>(`${environment.apiUrl}/logout`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    localStorage.removeItem("currentUserToken");
                    localStorage.removeItem("currentUserDetails");
                    this.currentUserSubject.next(null);
                   // this.currentUserSubject.next(data);
                    return data;
                })
            );
    }

    forgotpassword(email: string): Observable<any> {
        // var data: any = new FormData();
        // data.append("locale", "en");
        // data.append("email", email);

        let data = {
            locale: "en",
            email: email
        };
        return this.http
            .get<any>(`${environment.apiUrl}/forgot-password`, {
                params: data,
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }


    forgotpasswordOtp(id,otp: string): Observable<any> {
        let data = {
            locale: "en",
            user_id: id,
            otp: otp
        };
        return this.http
            .get<any>(`${environment.apiUrl}/forgot-password/verify-otp`, {
                params: data,
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    resetPassword(
        id:string,
        otp:string,
        password: string,
        fcm_id: string,
        device_id: string,
        device_type: string
    ): Observable<any> {
        let fcmToken= localStorage.getItem('fcmToken');

        var data: any = new FormData();
        data.append("locale", "en");
        data.append("user_id", id);
        data.append("otp", otp);
        data.append("password", password);
        data.append("fcm_id", fcmToken);
        data.append("device_id", fcmToken);
        data.append("device_type", 'web');

        
        return this.http
            .post<any>(`${environment.apiUrl}/forgot-password/reset-password`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(user => {
                    localStorage.setItem(
                        "currentUserToken",
                        JSON.stringify(user.data.token)
                    );
                    localStorage.setItem(
                        "currentUserDetails",
                        JSON.stringify(user.data.data)
                    );
                    this.currentUserSubject.next(user);
                    return user;
                })
            );
    }



    setPassword(
        hash:string,
        password: string,
        fcm_id: string,
        device_id: string,
        device_type: string
    ): Observable<any> {
        let fcmToken= localStorage.getItem('fcmToken');

        var data: any = new FormData();
        data.append("locale", "en");
        data.append("hash", hash);
        data.append("password", password);
        data.append("fcm_id", fcmToken);
        data.append("device_id", fcmToken);
        data.append("device_type", 'web');

        
        return this.http
            .post<any>(`${environment.apiUrl}/activation-link/verify`, data, {
                headers: httpHeaders
            })
            .pipe(
                map(user => {
                    localStorage.setItem(
                        "currentUserToken",
                        JSON.stringify(user.data.token)
                    );
                    localStorage.setItem(
                        "currentUserDetails",
                        JSON.stringify(user.data.data)
                    );
                    this.currentUserSubject.next(user);
                    return user;
                })
            );
    }

    resetToken(): Observable<any> {
        let data = {
            locale: "en"            
        };
        return this.http
            .get<any>(`${environment.apiUrl}/user/token/refresh`, {
                params: data,
                headers: httpHeaders
            })
            .pipe(
                map(data => {
                    localStorage.setItem(
                        "currentUserToken",
                        JSON.stringify(data.data.token)
                    );
                    //return data;
                })
            );
    }
}
