import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import {AuthGuard} from '../shared/guard';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        // canActivateChild : [AuthGuard],
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'prefix' },
            { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'clients', loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule) },
            { path: 'profile/:id', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
            { path: 'devices/:id', loadChildren: () => import('./devices/devices.module').then(m => m.DevicesModule) },
            {path: 'devices-type', loadChildren:() => import ('./devices-type/devices-type.module').then(m => m.DevicesTypeModule)},
            {
                path: 'device-details/:devicetype/:id',
                loadChildren: () => import('./device-details/device-details.module').then(m => m.DeviceDetailsModule)
            },           
            {
                path: 'master-component-details/:id',
                loadChildren: () =>
                    import('./master-component-details/master-component-details.module').then(m => m.MasterComponentDetailsModule)
            },
            { path: 'components', loadChildren: () => import('./bs-component/bs-component.module').then(m => m.BsComponentModule) },
            { path: 'inventory', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule) },
            {
                path: 'master-component',
                loadChildren: () => import('./master-component/master-component.module').then(m => m.MasterComponentModule)
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LayoutRoutingModule {}
