import { Component, OnInit, HostBinding } from '@angular/core';
// import { MessagingService } from "./shared/services";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', './app.component.skins.less']
})
export class AppComponent implements OnInit {
  message;
  public tenantStored;
  constructor(
    // private messagingService: MessagingService
    ) { }

  @HostBinding("class.theme-admin") public adminTheme: boolean;
  @HostBinding("class.theme-user") public clientTheme: boolean;
  ngOnInit() {
    this.tenantStored = localStorage.getItem("tenant");
    this.enableThemes();

    // this.messagingService.requestPermission();
    // this.messagingService.receiveMessage();
    // this.message = this.messagingService.currentMessage;    
  }
  enableThemes() {
    console.log('ENABLE THEME CALLED==');
    this.adminTheme = this.tenantStored === 'admin';
    this.clientTheme = this.tenantStored !== 'admin';
  }
}