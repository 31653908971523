// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: 'https://api.iloggerz.com/api/v1',
   //apiUrl: 'http://192.168.1.50:3036/api/v1',
   imageUrl:'http://api.iloggerz.com/uploads/images/',
   
   firebase: {
    apiKey: "AIzaSyCuN47D0S6iftDDPBQhQRT40gTERYEHRBw",
    authDomain: "iot-pro-f65be.firebaseapp.com",
    databaseURL: "https://iot-pro-f65be.firebaseio.com",
    projectId: "iot-pro-f65be",
    storageBucket: "iot-pro-f65be.appspot.com",
    messagingSenderId: "776722469439",
    appId: "1:776722469439:web:f9eaa138e86a815a231e8a",
    measurementId: "G-4C52H1NFL3"
  }
  //  {
  //   apiKey: "your apikey",
  //   authDomain: "your authDomain",
  //   databaseURL: "your databaseUrl",
  //   projectId: "your projectId",
  //   storageBucket: "your storageBucket",
  //   messagingSenderId: "your messagingSenderId"
  // }   
  

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.