import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthenticationService } from '../services';

@Injectable()
export class UserAuthGuard implements CanActivate, CanActivateChild {

    constructor(private router: Router, private authenticationService: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        console.log('USERauthgaurd called=='+localStorage.getItem("tenant"));

        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser && localStorage.getItem("tenant") != 'admin') {

            console.log('USER GAURD loaded=='+state);
            // this.router.navigate(['']);
            return true;
        }
        this.router.navigate(['/login']);

        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
            console.log('USERauthgaurd called=='+localStorage.getItem("tenant"));

        if (localStorage.getItem("tenant")!= 'admin') {
            return true;
        }
        return false;
    }
}
