export class User {
    id: number;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    authdata?: string;
}

export class token {
    token?: string;
}
