import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
    FormBuilder,
    FormGroup,
    FormArray,
    Validators,
    FormControl
} from "@angular/forms"; 
import { MasterComponentService, DeviceService } from "@app/shared/services";
@Component({
    selector: 'add-device-modal',
    templateUrl: './add-device-modal.component.html',
    styleUrls: ['./add-device-modal.component.scss']
})
export class AddDeviceModalComponent {
    @Input() device_type_id: string;

    @Output() valueChange = new EventEmitter();
    refreshList:boolean=false;

    closeResult: string;
    demoForm: FormGroup;

    form = new FormGroup({
        fname: new FormControl(""),
        lname: new FormControl(""),
        email: new FormControl(""),
        dial_code: new FormControl(""),
        mobile: new FormControl(""),
        subdomain: new FormControl(""),
    });


    deviceCreateForm = new FormGroup({
        sno: new FormControl("", Validators.required),
        frequency: new FormControl("",Validators.required)
    });
    submitted = false;
    error = "";
    loading = false;
    errMsg:any;

    constructor(
        private modalService: NgbModal,
        private _formBuilder: FormBuilder,
        private componentService: MasterComponentService,
        private deviceService: DeviceService
    ) {
        this.demoForm = this._formBuilder.group({
            demoArray: this._formBuilder.array([])
        });

    }

    open(content) {     
      this.modalService.open(content, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    get demoArray() {
        return this.demoForm.get('demoArray') as FormArray;
    }
    get f() {
        return this.deviceCreateForm.controls;
    }

    onSubmit() {    
        this.submitted = true;
        // stop here if form is invalid
        if (this.deviceCreateForm.invalid) {
            return;
        }
        this.loading = true;
        this.deviceService
            .activateDevice(this.deviceCreateForm.value)
            .subscribe(
                data => {
                    this.loading = false;
                    this.onReset();
                    this.errMsg = ""
                    //this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.loading = false;
                    this.errMsg = JSON.stringify(error.error.errors)
                    console.log("ERROR1 " + JSON.stringify(error.error.errors));
                    console.log("ERROR2 " + JSON.stringify(error));
                    //this.loading = false;
                }
            );
    }

    onReset() {
         this.submitted = false;
        this.deviceCreateForm.reset();
        this.valueChange.emit(this.refreshList);
        this.modalService.dismissAll();  
    }
}

