import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    AfterViewInit,
    EventEmitter,
    Output 
} from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import {
    FormBuilder,
    FormGroup,
    FormArray,
    Validators,
    FormControl
} from "@angular/forms";
import { MasterComponentService } from "@app/shared/services";
@Component({
    selector: "master-component-modal",
    templateUrl: "./master-component-modal.component.html",
    styleUrls: ["./master-component-modal.component.scss"]
})
export class MasterComponentModalComponent implements AfterViewInit {
    @Output() valueChange = new EventEmitter();
    refreshList:boolean=false;

    closeResult: string;
    public demoForm: FormGroup;
    public invoiceForm: FormGroup;
    @Input() id: string;
    @ViewChild("content", { static: true }) content: ElementRef;
    types = [
        { name: "Digital", val: "1" },
        { name: "Analog", val: "0" }
    ];
    form = new FormGroup({
        name: new FormControl(""),
        description: new FormControl(""),
        attribute: new FormControl(""),
        icon: new FormControl(""),
        type: new FormControl(this.types[0].val)
    });

    MasterComponentForm=  new FormGroup({
        name: new FormControl("", Validators.required),
        description: new FormControl("", Validators.required),
        icon: new FormControl('', Validators.required),
        attribute: new FormControl("", Validators.required),
        type: new FormControl(this.types[0].val)
    });

    ngAfterViewInit() {
    }

    submitted = false;
    error = "";
    loading = false;
    constructor(
        private modalService: NgbModal,
        private _formBuilder: FormBuilder,
        private componentService: MasterComponentService
    ) {
       
        this.invoiceForm = this._formBuilder.group({
            itemRows: this._formBuilder.array([this.initItemRows()])
        });
    }

    open(content) {
        this.modalService.open(content, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;                        
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return "by pressing ESC";
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return "by clicking on a backdrop";
        } else {
            return `with: ${reason}`;
        }
    }

    get formArr() {
        return this.invoiceForm.get("itemRows") as FormArray;
    }

    initItemRows() {
        return this._formBuilder.group({
            itemname: [""]
        });
    }

    addNewRow() {
        this.formArr.push(this.initItemRows());
    }

    deleteRow(index: number) {
        this.formArr.removeAt(index);
    }

    get f() {
        return this.MasterComponentForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        if (this.MasterComponentForm.invalid) {
            return;
        }
        this.loading = true; 
            this.componentService
            .createComponent(this.MasterComponentForm.value)
            .subscribe(
                data => {
                    this.loading = false;
                    this.onReset();
                },
                error => {
                    this.loading = false;
                    console.log("ERROR " + JSON.stringify(error));
                }
            );
    }

    onReset() {
        this.submitted = false;
        this.MasterComponentForm.reset();
        this.valueChange.emit(this.refreshList);
        this.modalService.dismissAll();   
    }
}
