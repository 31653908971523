import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "@app/shared/services";
import { ConditionalExpr } from '@angular/compiler';
@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
    public pushRightClass: string;
    public userDetails: any;
    public userName: any;
    public userId: any;

    constructor(
        private translate: TranslateService,
        public router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.router.events.subscribe(val => {
            if (
                val instanceof NavigationEnd &&
                window.innerWidth <= 992 &&
                this.isToggled()
            ) {
                this.toggleSidebar();
            }
        });
    }

    ngOnInit() {
        this.pushRightClass = "push-right";
        this.userDetails = JSON.parse(localStorage.getItem("currentUserDetails"));
        this.userName = this.userDetails.fname + ' ' + this.userDetails.lname;
        this.userId = this.userDetails.id;
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector("body");
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector("body");
        dom.classList.toggle(this.pushRightClass);
    }

    rltAndLtr() {
        const dom: any = document.querySelector("body");
        dom.classList.toggle("rtl");
    }

    onLoggedout() {
        localStorage.removeItem("isLoggedin");
        this.authenticationService.logout().subscribe(
            data => {
                this.router.navigate(["/login"]);
            },
            error => {
                console.log("ERROR " + JSON.stringify(error));
            }
        );
    }

    changeLang(language: string) {
        this.translate.use(language);
    }
    openProfile() {
        // this.router.navigate(["/profile",this.userId]);

        this.router.navigate(['/profile', this.userId]).then(() => window.location.reload());
    }
}
