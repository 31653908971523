import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';

import {
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatStepperModule,
    MatFormFieldModule
} from '@angular/material';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BasicAuthInterceptor, ErrorInterceptor, SocketService } from './shared/services';

import {UserLayoutModule} from './user-layout/user-layout.module';
import {LayoutModule} from './layout/layout.module';



import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule, AngularFireAuth } from "@angular/fire/auth";
import {AngularFireMessaging} from "@angular/fire/messaging"
import { environment } from '../environments/environment';

/* Auth service */
// import { AuthenticationService } from './shared/authentication.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LanguageTranslationModule,
        AppRoutingModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatStepperModule,
        ReactiveFormsModule,
        LayoutModule,
        UserLayoutModule,
        AngularFireAuthModule,        
        AngularFireModule.initializeApp(environment.firebase),
    ],
    exports: [MatFormFieldModule],
    declarations: [AppComponent],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        AuthGuard,
        SocketService,
        AngularFireAuth,
        AngularFireMessaging
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
